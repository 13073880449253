.navbar{
    height:60px;
}

.welcomeBgImg {
    background-image: url('../assets/download.png');
}

.welcome-content {
    width:100vw;
    height:100vh;
    background-color: rgba(28,40,65, 0.3);
}

.autoPlayVideo {
    height: 100vh;
}

.welcome-logo {
    margin-left: 25px;
    color: white;
}

.welcome-video {
    position: absolute;
    z-index:-1;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.welcome-section {
    width:100%;
    height:100%;
    text-align:center;
}

.welcomeText {
    font-size:32px;
    font-weight:'bold';
    color:white;
}


.login-btn {
    background-color: #0ac5bf !important;
    border-radius: 0 !important;
    border: 0px solid #0ac5bf;
    margin-left: auto;
    margin-right: 25px;
}

.welcome-signup-btn {
    background-color: #0ac5bf !important;
    border-radius: 0 !important;
    border: 0px solid #0ac5bf;
}

.suggest-btn {
    background-color: #0ac5bf !important;
    border-radius: 0 !important;
    border: 0px solid #0ac5bf;
}
.header-content{
    height: 100%;
    width: 100%;
}

.inner-header-content {
    padding: 20px;
    background-color: rgba(255,255,255,0);
}

.white-text {
    color: white;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.alt-welcome-section{
    padding: 10px 0 140px 0;
    border-bottom: 1px solid rgb(179, 208, 255, 0.7);
}

.section-title {
    padding:  60px 0;
    text-align: center;
}

.navBtn {
    color: white;
    fontWeight: bold;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    padding: 0 10px;
}




