.layout-content {
    width:100vw;
    min-height:100vh;
    /*background-color: #1c2841;*/
    text-align: center;
    overflow:scroll;
}

.layout-body {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0);
    text-align: left;
    overflow: hidden;
}

.layout-nav {
    height: 60px;
    background-color: lightblue;
}

label {
    opacity: 0.7;
}

Button {
    background-color: #0ac5bf !important;
    border-radius: 0 !important;
    border-width: 0 !important;
}

.page-content {
    margin-top: 40px;
    padding: 20px;
}

.website-logo {
    margin-left: 25px;
    color: #1c2841;
}

.bgImg {
    min-height: 100vh;
    background-image: url('../assets/download.png');
}

.loggedInBtns {
    margin-left: auto;
    margin-right: 60px;
}

.loggedInBtn {
    margin-left: 15px;
}

.logout-btn {
    background-color: #0ac5bf !important;
    border-radius: 0 !important;
    border: 0px solid #0ac5bf !important;
    margin-right: 25px;
}

.login-btn {
    background-color: #0ac5bf !important;
    border-radius: 0 !important;
    border: 0px solid #0ac5bf;
    margin-left: auto;
    margin-right: 25px;
}

.card, input {
    border-radius: 0 !important;
}

.card {
    background-color: rgba(255, 255, 255, 0.5);
}

.list-item {
    background-color: rgb(179, 208, 255, 0.3);
    padding: 10px;
    margin-bottom: 2px;
}
.save-btn, .save-form {
    margin-left: auto;
}

.suggested_by {
    margin-left: auto;
    margin-right:20px
}

.hide {
    display: none;
}

.bold {
    font-weight: bold;
}
    
.graytext {
    color: #888;
}

.graybackground {
    background-color: rgb(179, 208, 255, 0.3);
    padding:50px 0;
}

.repeat-btn {
    background-color: transparent !important;
    color:#0ac5bf;
}

.delete-name-btn {
    background-color: transparent !important;
    color: #0ac5bf;
}

.trash-btn {
    color:white;
    opacity: 0.6;
}

.tabs {
    padding:10px;
    border-bottom: 1px solid rgb(179, 208, 255, 0.3);
}

.tab {
    padding: 10px 20px;
    color: #0ac5bf;
    border: 0.5px solid rgb(179, 208, 255, 0.3);
    border-bottom: 0 solid transparent;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 12px;
}




